









































































































// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, computed, ref } from '@vue/composition-api';
// Import plugins ----------------------------------------------------------------------------------
import { usePodocoreModule, usePodocoreModuleService } from '@/plugins/podocore';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'LayoutDefaultNavbarWorkspace',
  setup() {
    const menu = ref(false);

    const busModule = usePodocoreModule('bus');

    const { useSaas } = usePodocoreModule('saas');
    const { currentPlanName } = useSaas();

    const saasService = usePodocoreModuleService('saas');

    const doctorService = usePodocoreModuleService('doctor');
    const workspacesService = usePodocoreModuleService('workspaces');

    const doctor = (doctorService.state.value as any).context.doctor;

    const initials = computed(() => {
      return doctor.profile.firstName.slice(0, 1) + doctor.profile.lastName.slice(0, 1);
    });

    const workspaces = computed(() => {
      return (workspacesService.state.value as any).context.workspaces;
    });

    const defaultWorkspaces = computed(() =>
      workspaces.value.docs.filter((workspace: any) => workspace.defaultForDoctorCuid === doctor.cuid)
    );

    const notDefaultWorkspaces = computed(() =>
      workspaces.value.docs.filter((workspace: any) => workspace.defaultForDoctorCuid !== doctor.cuid)
    );

    const currentWorkspace = computed(() => {
      return (workspacesService.state.value as any).context.current;
    });

    const isSaasFetching = computed(() => {
      return (saasService.state.value as any).matches('fetching');
    });

    function selectWorkspace(workspaceCuid?: string) {
      workspacesService.send({
        type: 'SELECT',
        data: {
          workspaceCuid
        }
      } as any);

      menu.value = false;
    }

    function createWorkspace() {
      busModule.publish(busModule.events.openDialog({ id: 'workspace-create' }));

      menu.value = false;
    }

    function textColor(color: string) {
      const rgb = hexToRgb(color);
      if (!rgb) return 'white';
      const brightness = Math.round((rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000);
      return brightness > 125 ? 'black' : 'white';
    }

    function hexToRgb(hex: string) {
      // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
      const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
      hex = hex.replace(shorthandRegex, function (m, r, g, b) {
        return r + r + g + g + b + b;
      });

      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result
        ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
          }
        : null;
    }

    return {
      // Values
      menu,
      doctor,
      initials,
      workspaces,
      defaultWorkspaces,
      notDefaultWorkspaces,
      currentWorkspace,
      currentPlanName,
      // Methods
      selectWorkspace,
      createWorkspace,
      textColor,
      // Flags
      isSaasFetching
    };
  }
});
