












































// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent, ref, watch } from '@vue/composition-api';
// Import plugins ----------------------------------------------------------------------------------
import { usePodocoreModuleService } from '@/plugins/podocore';
// Import utils ------------------------------------------------------------------------------------
import { useI18n } from '@/utils/i18n.utils';
import { useAnalytics } from '@/utils/analytics.utils';
// Import components -------------------------------------------------------------------------------
import CompositeDialog from '@/components/composite/CompositeDialog.vue';
import AlertError from '../alerts/AlertError.vue';
// Import types ------------------------------------------------------------------------------------
import type { DialogId } from '@/plugins/podocore/modules/bus/bus.module';
import type { LocaleMessages } from 'vue-i18n';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'DialogTermsAndConditions',
  components: { CompositeDialog, AlertError },
  props: {
    filterMeta: {
      type: Object,
      default: null
    },
    consentDocument: {
      type: Object,
      required: true
    },
    closable: {
      type: Boolean,
      default: true
    },
    standalone: {
      type: Boolean,
      default: false
    }
  },
  setup(properties, { root }) {
    const dialogId: DialogId = 'terms-and-conditions';
    const rDialog = ref<any>(null);

    const { trackSuccess, trackFailure } = useAnalytics();
    const { currentLang } = useI18n();

    let doctorService: any;

    if (properties.standalone) doctorService = usePodocoreModuleService('doctor');

    const currentDocument = computed(() => {
      const result: Record<'url' | 'title', LocaleMessages | string | undefined> = {
        url: undefined,
        title: undefined
      };

      result.url = (
        properties.consentDocument.links.find((link: any) => link.lang === currentLang) ??
        properties.consentDocument.links.find((link: any) => link.lang === 'en')
      ).url;

      switch (properties.consentDocument.type) {
        case 'TOU':
          result.title = root.$t('commons.sentences.account.terms-and-conditions.doc-tou.title');
          break;
        case 'DPA':
          result.title = root.$t('commons.sentences.account.terms-and-conditions.doc-dpa.title');
          break;
        default:
          result.title = root.$t('commons.sentences.terms-conditions');
      }

      return result;
    });

    function consent(documentCuid: string) {
      doctorService.send({
        type: 'CONSENT',
        data: {
          documentCuid
        }
      } as any);
    }

    const isSuccessed = computed(() =>
      [{ patching: { consent: 'success' } }].some((element) => doctorService?.state.value.matches(element))
    );
    watch(isSuccessed, (value) => {
      if (value) trackSuccess('User consentdoc', { document: properties.consentDocument.type, value: true });
    });

    const isErrored = computed(() =>
      [{ patching: { consent: 'failure' } }].some((element) => doctorService?.state.value.matches(element))
    );
    watch(isErrored, (value) => {
      if (value) trackFailure('User consentdoc', 'Document consent failed');
    });

    const isDisabled = computed(
      () =>
        ![
          { patching: { consent: 'success' } },
          { patching: { consent: 'failure' } },
          'success'
        ].some((element) => doctorService?.state.value.matches(element))
    );

    const isLoading = computed(() =>
      [{ patching: { consent: 'processing' } }].some((element) => doctorService?.state.value.matches(element))
    );

    return {
      rDialog,
      dialogId,
      currentDocument,
      doctorService,
      isErrored,
      isDisabled,
      isLoading,
      // Methods
      consent
    };
  }
});
