


























// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent } from '@vue/composition-api';
import { useTimestamp } from '@vueuse/core';
// Import utils ------------------------------------------------------------------------------------
import { useNotifications } from '@/utils/notifications.utils';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'Events',
  setup() {
    const { notifications, clickNotification } = useNotifications();

    const { timestamp } = useTimestamp({ offset: 0 });

    const poppedNotifications = computed(() =>
      notifications.value.filter((notification) => notification.popped).reverse()
    );

    return {
      // Values
      poppedNotifications,
      timestamp,
      // Methods
      clickNotification
    };
  }
});
