










































// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent, reactive, ref, watch } from '@vue/composition-api';
import { usePodocoreModule } from '@/plugins/podocore';
import { isNull, isString, isUndefined } from 'lodash';
// Import configurations ---------------------------------------------------------------------------
import { apiConfig } from '@/config/api.config';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'LayoutDefaultNavbarSearchPatient',
  setup(_, { root }) {
    const rSearch = ref<any>(null);

    const busModule = usePodocoreModule('bus');

    const { data, error, isPending, request, clear, cancel } = usePodocoreModule(
      'request'
    ).useAuthenticatedRequest(`${apiConfig.default}/patients`);

    const search = ref('');
    const selection = ref(null);

    const selectedPatient = ref(null);

    const searchHaveValue = computed(() => {
      return !isNull(search.value) && !isUndefined(search.value) && search.value.length > 0;
    });

    const isDelayedPending = ref(false);

    const sanitizedData = computed(() => {
      return (
        data.value ?? {
          docs: [],
          totalDocs: 0
        }
      );
    });

    const searchDelay = reactive({
      config: 400,
      timeout: undefined as any
    });

    // Do a request when the "search" is updated
    watch(search, (value) => {
      if (!searchHaveValue.value) {
        clear();
        return;
      }

      // Clear timeout
      if (searchDelay.timeout) clearTimeout(searchDelay.timeout);

      isDelayedPending.value = true;

      if (isString(value) && value.length > 0) {
        searchDelay.timeout = setTimeout(() => {
          request({ axios: { params: { search: value.trim() } } });
        }, searchDelay.config);
      }
    });

    watch(isPending, (value) => {
      if (!value) {
        isDelayedPending.value = false;
      }
    });

    function openPatient(patientCuid: any) {
      if (root.$router.currentRoute.path !== `/patients/${patientCuid}`) {
        root.$router.push({ path: `/patients/${patientCuid}` });
      }

      rSearch.value.$el.querySelector('input')?.blur();

      search.value = '';

      busModule.publish(busModule.events.patientSwitched({ patientCuid }));
    }

    busModule.useEventSubscriber(busModule.events.patientUnloaded, () => {
      search.value = '';
      selectedPatient.value = null;

      selection.value = null;

      clear();
      cancel();
    });

    return {
      // References
      rSearch,
      // Values
      selection,
      search,
      sanitizedData,
      error,
      selectedPatient,
      // Methods
      openPatient,
      // Flags
      isDelayedPending
    };
  }
});
